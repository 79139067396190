import React, { useState, useEffect, useCallback } from "react";
import { Box, Text, VStack, Flex, ChakraProvider, Button, Spinner, HStack, IconButton } from "@chakra-ui/react";
import { ChevronLeftIcon, ChevronRightIcon, RepeatIcon } from "@chakra-ui/icons";
import BlackBox from "../Landing/InfoTopBox";
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { obtenerHistorialPedidos, obtenerTodosPedidos } from "../Redux/Slice";
import OrderBox from "./OrderBox";
import { FaClipboardList, FaCheckCircle, FaTimesCircle, FaSpinner } from 'react-icons/fa'

const Arrow = (
  <svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.15 6.99952H1C0.716667 6.99952 0.479167 6.90369 0.2875 6.71202C0.0958333 6.52036 0 6.28286 0 5.99952C0 5.71619 0.0958333 5.47869 0.2875 5.28702C0.479167 5.09536 0.716667 4.99952 1 4.99952H12.15L9.3 2.14952C9.1 1.94952 9.00417 1.71619 9.0125 1.44952C9.02083 1.18286 9.11667 0.949523 9.3 0.749523C9.5 0.549523 9.7375 0.445357 10.0125 0.437023C10.2875 0.42869 10.525 0.524523 10.725 0.724523L15.3 5.29952C15.4 5.39952 15.4708 5.50786 15.5125 5.62452C15.5542 5.74119 15.575 5.86619 15.575 5.99952C15.575 6.13286 15.5542 6.25786 15.5125 6.37452C15.4708 6.49119 15.4 6.59952 15.3 6.69952L10.725 11.2745C10.525 11.4745 10.2875 11.5704 10.0125 11.562C9.7375 11.5537 9.5 11.4495 9.3 11.2495C9.11667 11.0495 9.02083 10.8162 9.0125 10.5495C9.00417 10.2829 9.1 10.0495 9.3 9.84952L12.15 6.99952Z" fill="#1C1B1F"/>
  </svg>
);

const orderPriority = {
  "pendiente": 1,
  "en_proceso": 2,
  "finalizado": 3,
  "cancelado": 4
};

export default function Pedidos() {
  const dispatch = useDispatch();
  const { historial, user, status, error } = useSelector(state => state);
  const [selectedStatus, setSelectedStatus] = useState("pendiente");

  const fetchPedidos = useCallback(() => {
    if (user?.role === "Admin") {
      dispatch(obtenerTodosPedidos());
    } else {
      dispatch(obtenerHistorialPedidos());
    }
  }, [dispatch, user?.role]);

  useEffect(() => {
    fetchPedidos();

    // Set up auto-refresh for admin every 2 minutes
    let intervalId;
    if (user?.role === "Admin") {
      intervalId = setInterval(fetchPedidos, 2 * 60 * 1000);
    }

    return () => {
      if (intervalId) clearInterval(intervalId);
    };
  }, [fetchPedidos, user?.role]);

  const handleRefresh = () => {
    fetchPedidos();
  };
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('es-ES');
  };

  const filteredAndSortedPedidos = React.useMemo(() => {
    let filtered = historial?.filter(pedido => {
      if (selectedStatus === "todos") return true;
      return pedido?.attributes?.estado === selectedStatus;
    }) || [];

    return filtered.sort((a, b) => {
      if (selectedStatus === "todos") {
        return orderPriority[a?.attributes?.estado] - orderPriority[b?.attributes?.estado];
      }
      return new Date(b?.attributes?.createdAt) - new Date(a?.attributes?.createdAt);
    });
  }, [historial, selectedStatus]);

  const handleStatusChange = (newStatus) => {
    setSelectedStatus(newStatus);
  };

  return (
    <ChakraProvider>
      <Box position="relative" minHeight="100vh" pb="60px">
        
        <VStack spacing={4} p={4}>
          <BlackBox
            titulo="Pedidos"
            info="Seleccioná cualquiera de los pedidos para conocer el detalle del mismo."
            showBackButton={true}
          />
        <Flex justifyContent="flex-end" p={2} width={"100%"}>
          <IconButton
            icon={<RepeatIcon />}
            onClick={handleRefresh}
            aria-label="Refresh orders"
            isLoading={status === 'loading'}
          />
        </Flex>
          {status === 'loading' && <Spinner />}
          {status === 'failed' && <Text color="red.500">Error: {error}</Text>}
          {status === 'succeeded' && filteredAndSortedPedidos.length > 0 ? (
            filteredAndSortedPedidos.map(pedido => (
              <OrderBox 
                key={pedido?.id}
                date={formatDate(pedido?.attributes?.createdAt)} 
                orderNumber={pedido?.id} 
                total={pedido?.attributes?.total}
                items={pedido?.attributes?.pedido_articulos?.data}
                estado={pedido?.attributes?.estado}
              />
            ))
          ) : status === 'succeeded' ? (
            <Text>No hay pedidos disponibles para el estado seleccionado.</Text>
          ) : null}
        </VStack>

        {user?.role === "Admin" && (
          <HStack spacing={2} width="100%" style={styles.container}>
            <Button height={"44px"} display={"flex"} flexDir={"column"} leftIcon={<FaClipboardList />} fontSize={"12px"} onClick={() => handleStatusChange("pendiente")} colorScheme={selectedStatus === "pendiente" ? "blue" : "gray"}>Pendientes</Button>
            <Button height={"44px"} display={"flex"} flexDir={"column"} leftIcon={<FaSpinner />} fontSize={"12px"} onClick={() => handleStatusChange("en_proceso")} colorScheme={selectedStatus === "en_proceso" ? "blue" : "gray"}>En Proceso</Button>
            <Button height={"44px"} display={"flex"} flexDir={"column"} leftIcon={<FaCheckCircle />} fontSize={"12px"} onClick={() => handleStatusChange("finalizado")} colorScheme={selectedStatus === "finalizado" ? "blue" : "gray"}>Finalizados</Button>
            <Button height={"44px"} display={"flex"} flexDir={"column"} leftIcon={<FaTimesCircle />} fontSize={"12px"} onClick={() => handleStatusChange("cancelado")} colorScheme={selectedStatus === "cancelado" ? "blue" : "gray"}>Cancelados</Button>
          </HStack>
        )}
      </Box>
    </ChakraProvider>
  );
}

const styles = {
  container: {
    width: "100%",
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    padding: "1rem",
    backgroundColor: "white",
    bottom: 0,
    left: 0,
    right: 0,
    position: "fixed",
    zIndex: 1000, 
  },
}