import React, { useState, useEffect } from "react";
import Hero from "./Hero";
import Categorias from "./Categorias";
import Carousel from "./MasVendidos";
import FirtsInfo from "./FirstInfo";
import { useSelector } from "react-redux";
import BlackBoxBottom from "./InfoBottomBox";
import { width } from "@fortawesome/free-solid-svg-icons/fa0";
import ResponsiveNav from "./ResponsiveNav";
import { Flex } from "@chakra-ui/react";

function Landing() {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [isTablet, setIsTablet] = useState(
    window.innerWidth > 768 && window.innerWidth <= 1780
  );
  const categorias = useSelector((state) => state.categories);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
      setIsTablet(window.innerWidth > 768 && window.innerWidth <= 1780);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const styles = {
    container2: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      padding: isTablet ? "2rem" : "1rem",
      paddingBottom: "80px",
      overflow: "hidden",
      gap: isMobile ? "0" : isTablet ? "1.5rem" : "2rem",
    },
    hero: {
      display: "flex",
      alignItems: "center",
      overflow: "hidden",
      width: "100%",
      flexDirection: isMobile ? "column" : isTablet ? "row-reverse" : "row",
    },
  };

  return (
    <div width="100%" style={{marginTop:"80px"}}>
      <ResponsiveNav />
      <div style={styles.container2}>
        <div style={styles.hero}>
          <Hero />
        </div>
        <FirtsInfo />
        <Categorias />
        {/* <SimpleThreeColumns /> */}
        <Flex
          width={"100%"}
          style={{
            flexDirection: isMobile ? "column" : isTablet ? "column" : "row",
          }}
          marginTop={isMobile ? "0" : " 5rem 0 "}
        >
          <Carousel />
          <BlackBoxBottom
            titulo1={"Horarios de atención"}
            info1="lu - vie: 00:00 a 00:00"
            titulo2="Contacto"
            info2="(291)xxx xxx x"
          />
        </Flex>
      </div>
    </div>
  );
}

export default Landing;
