import React from "react";
import { Box, Text, Button, Flex, VStack, Image, Skeleton, SkeletonText } from "@chakra-ui/react";
import { NavLink } from "react-router-dom";
import "../Landing/Landing.css";

const URL_BASE = process.env.PUBLIC_URL;

const ProductCard = ({ product, isLoading }) => {
  const [imageLoaded, setImageLoaded] = React.useState(false);

  const formatPrice = (price) => {
    if (typeof price !== "number") return price;
    return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  };

  const calculateDiscountedPrice = (price, discount) => {
    return price * (1 - discount / 100);
  };

  const noSelectStyle = {
    userSelect: "none",
    WebkitUserSelect: "none",
    MozUserSelect: "none",
    msUserSelect: "none",
  };

  if (isLoading) {
    return (
      <Box className="card" position="relative">
        <VStack spacing={0} alignItems={"flex-start"}>
          <Skeleton height="200px" width="100%" />
          <SkeletonText mt="4" noOfLines={1} spacing="4" width="80%" />
          <SkeletonText mt="2" noOfLines={2} spacing="4" />
          <Skeleton height="45px" width="100%" mt="4" />
        </VStack>
      </Box>
    );
  }

  return (
    <Box className="card" position="relative" {...noSelectStyle}>
      <VStack spacing={0} alignItems={"flex-start"} gap={".5rem"}>
        {product?.DescPorciento != null && product?.DescPorciento !== 0 && (
          <Box
            position="absolute"
            top="15px"
            left="15px"
            bg="#318215"
            color="white"
            px="2"
            py="1"
            borderRadius="md"
            fontSize="sm"
            fontWeight="bold"
            zIndex="1"
          >
            -{product?.DescPorciento}%
          </Box>
        )}
        <Box className="image_container">
          <Skeleton isLoaded={imageLoaded} height="200px" width="100%">
            <Image
              src={URL_BASE + product?.img?.data}
              alt="carnde"
              width="100%"
              height="200px"
              objectFit="cover"
              onLoad={() => setImageLoaded(true)}
              onError={() => setImageLoaded(false)}
            />
          </Skeleton>
        </Box>
        <Box className="title">
          <Text marginBottom=".25rem">{product?.nombre}</Text>
        </Box>
        <Box className="price">
          {product?.DescPorciento != null && product?.DescPorciento !== 0 ? (
            <VStack alignItems="left" gap={1}>
              <Text color="#318215" fontWeight="bold">
                $
                {formatPrice(
                  calculateDiscountedPrice(
                    Number(product?.precioKG),
                    product?.DescPorciento
                  )
                )}{" "}
                /Kg
              </Text>
              <Text as="s" fontSize="xs" color="gray.500">
                ${formatPrice(Number(product?.precioKG))} /Kg
              </Text>
            </VStack>
          ) : (
            <Text>${formatPrice(Number(product?.precioKG))} /Kg</Text>
          )}
        </Box>
      </VStack>
      <Flex className="action">
        <Button
          as={NavLink}
          to={`/ProdDetalle/${product?.id}`}
          bgColor="#CA0017"
          color="white"
          width="100%"
          borderRadius="24px"
          height="45px"
        >
          Ir al producto
        </Button>
      </Flex>
    </Box>
  );
};

export default ProductCard;