import React, { useRef, useState, useEffect } from "react";
import {
  Box,
  Heading,
  Flex,
  Icon,
  Button,
  useMediaQuery,
} from "@chakra-ui/react";
import { ArrowForwardIcon, ArrowBackIcon } from "@chakra-ui/icons";
import "animate.css";
import { useSelector } from "react-redux";
import ProductCard from "../Catalogo/cardProd";

const Carousel = () => {
  const scrollRef = useRef(null);
  const [isLargerThan768] = useMediaQuery("(min-width: 768px)");
  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(true);
  const { categories } = useSelector((state) => state);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);

  const CatOfer = categories?.filter((cat) => cat.id === 1);
  const valoresArticulos = [];

  if (CatOfer?.length > 0 && CatOfer[0]?.sub_categorias) {
    CatOfer[0].sub_categorias.forEach((sub_categoria) => {
      sub_categoria?.articulos?.forEach((articulo) => {
        valoresArticulos.push(articulo);
      });
    });
  }

  const scroll = (scrollOffset) => {
    scrollRef.current.scrollLeft += scrollOffset;
    updateArrowVisibility();
  };

  const updateArrowVisibility = () => {
    if (scrollRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = scrollRef.current;
      setShowLeftArrow(scrollLeft > 0);
      setShowRightArrow(scrollLeft < scrollWidth - clientWidth - 1);
    }
  };

  const handleMouseDown = (e) => {
    setIsDragging(true);
    setStartX(e.pageX - scrollRef.current.offsetLeft);
    setScrollLeft(scrollRef.current.scrollLeft);
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const handleMouseMove = (e) => {
    if (!isDragging) return;
    e.preventDefault();
    const x = e.pageX - scrollRef.current.offsetLeft;
    const walk = (x - startX) * 2;
    scrollRef.current.scrollLeft = scrollLeft - walk;
    updateArrowVisibility();
  };

  useEffect(() => {
    const scrollContainer = scrollRef.current;
    if (scrollContainer) {
      scrollContainer.addEventListener('scroll', updateArrowVisibility);
      return () => scrollContainer.removeEventListener('scroll', updateArrowVisibility);
    }
  }, []);

  return (
    <Box
      position="relative"
      maxW="100dvw"
      padding={isLargerThan768 ? "0 2rem" : "0"}
    >
      <Heading style={styles.h1}>Ofertas</Heading>
      <Flex position="relative" paddingTop="0px">
        {showLeftArrow && (
          <Button
            onClick={() => scroll(-200)}
            position="absolute"
            left="-.5rem"
            top="50%"
            transform="translateY(-50%)"
            zIndex="1"
            bg="white"
            _hover={{ bg: "gray.100" }}
          >
            <Icon as={ArrowBackIcon} boxSize={6} color="gray.500" />
          </Button>
        )}
        <Flex
          ref={scrollRef}
          style={{
            ...styles.container,
            cursor: isDragging ? 'grabbing' : 'grab'
          }}
          onMouseDown={handleMouseDown}
          onMouseUp={handleMouseUp}
          onMouseLeave={handleMouseUp}
          onMouseMove={handleMouseMove}
        >
          {valoresArticulos.map((articulo, index) => (
            <ProductCard
              key={index}
              product={articulo}
              className="animate__animated animate__backInUp"
            />
          ))}
        </Flex>
        {showRightArrow && (
          <Button
            onClick={() => scroll(200)}
            position="absolute"
            right="0"
            top="50%"
            transform="translateY(-50%)"
            zIndex="1"
            bg="white"
            _hover={{ bg: "gray.100" }}
          >
            <Icon as={ArrowForwardIcon} boxSize={6} color="gray.500" />
          </Button>
        )}
      </Flex>
    </Box>
  );
};

const styles = {
  container: {
    display: "flex",
    overflowX: "scroll",
    scrollBehavior: "smooth",
    gap: ".5rem",
    padding: ".5rem",
    alignItems: "center",
    msOverflowStyle: "none",
    scrollbarWidth: "none",
    "&::WebkitScrollbar": {
      display: "none",
    },
  },
  h1: {
    fontWeight: "bold",
    fontSize: "2rem",
    textAlign: "left",
    paddingLeft: "1rem",
  },
};

export default Carousel;