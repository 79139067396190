"use client";

import React from "react";
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  Flex,
  useColorModeValue,
  Text,
  Container,
  Box,
} from "@chakra-ui/react";
import { ChevronDownIcon, ChevronUpIcon } from "@chakra-ui/icons";
import BlackBox from "../Landing/InfoTopBox";

export default function Ayuda() {
  return (
    <div style={{padding:"1rem 0", marginTop:"80px"}}>
      <Box padding={"0 1rem"} marginBottom={"2rem"}>
        <BlackBox
          showBackButton={true}
          titulo={"Ayuda"}
          info={
            "En esta sección vas a encontrar la respuesta a las dudas más frecuentes"
          }
        />
      </Box>
      <Container>
        <Accordion allowMultiple width="100%">
          <AccordionItem>
            {({ isExpanded }) => (
              <>
                <AccordionButton
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  p={4}
                >
                  <Text fontSize="md">What is Chakra UI?</Text>
                  {isExpanded ? (
                    <ChevronUpIcon fontSize="24px" />
                  ) : (
                    <ChevronDownIcon fontSize="24px" />
                  )}
                </AccordionButton>
                <AccordionPanel pb={4}>
                  <Text textAlign={"left"} color="black">
                    Chakra UI is a simple and modular component library that
                    gives developers the building blocks they need to create web
                    applications.
                  </Text>
                </AccordionPanel>
              </>
            )}
          </AccordionItem>
          <AccordionItem>
            {({ isExpanded }) => (
              <>
                <AccordionButton
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  p={4}
                >
                  <Text fontSize="md">What advantages to use?</Text>
                  {isExpanded ? (
                    <ChevronUpIcon fontSize="24px" />
                  ) : (
                    <ChevronDownIcon fontSize="24px" />
                  )}
                </AccordionButton>
                <AccordionPanel pb={4}>
                  <Text textAlign={"left"} color="black">
                    Chakra UI offers a variety of advantages including ease of
                    use, accessibility, and customization options. It also
                    provides a comprehensive set of UI components and is fully
                    compatible with React.
                  </Text>
                </AccordionPanel>
              </>
            )}
          </AccordionItem>
          <AccordionItem>
            {({ isExpanded }) => (
              <>
                <AccordionButton
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  p={4}
                >
                  <Text fontSize="md">How to start using Chakra UI?</Text>
                  {isExpanded ? (
                    <ChevronUpIcon fontSize="24px" />
                  ) : (
                    <ChevronDownIcon fontSize="24px" />
                  )}
                </AccordionButton>
                <AccordionPanel pb={4}>
                  <Text textAlign={"left"} color="black">
                    To get started with Chakra UI, you can install it via npm or
                    yarn, and then import the components you need in your
                    project. The Chakra UI documentation is also a great
                    resource for getting started and learning more about the
                    library.
                  </Text>
                </AccordionPanel>
              </>
            )}
          </AccordionItem>
        </Accordion>
      </Container>
    </div>
  );
}
