import React, { useState, useEffect, useRef } from 'react';
import { Box, Image, Flex } from '@chakra-ui/react';
import { ArrowBackIcon, ArrowForwardIcon } from '@chakra-ui/icons';

const CarouselHero = ({ images }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [startX, setStartX] = useState(0);
  const [isDragging, setIsDragging] = useState(false);
  const carouselRef = useRef(null);

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
  };

  useEffect(() => {
    const interval = setInterval(nextSlide, 5000); // Auto-advance every 5 seconds
    return () => clearInterval(interval);
  }, []);

  const handleMouseDown = (e) => {
    setStartX(e.pageX - carouselRef.current.offsetLeft);
    setIsDragging(true);
  };

  const handleMouseMove = (e) => {
    if (!isDragging) return;
    e.preventDefault();
    const x = e.pageX - carouselRef.current.offsetLeft;
    const walk = (x - startX) * 3; // Multiplicar por 3 para aumentar la sensibilidad
    if (walk > 100) {
      prevSlide();
      setIsDragging(false);
    } else if (walk < -100) {
      nextSlide();
      setIsDragging(false);
    }
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  return (
    <Box 
      position="relative" 
      width="100%" 
      height="100%"
      ref={carouselRef}
      onMouseDown={handleMouseDown}
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
      onMouseLeave={handleMouseUp}
    >
      <Image
        src={images[currentIndex]}
        alt={`Slide ${currentIndex + 1}`}
        objectFit="cover"
        width="100%"
        height="100%"
        loading='lazy'
        draggable="false"
      />
      <Flex
        position="absolute"
        bottom="10px"
        left="50%"
        transform="translateX(-50%)"
      >
        {images.map((_, index) => (
          <Box
            key={index}
            width="10px"
            height="10px"
            borderRadius="50%"
            bg={index === currentIndex ? "red" : "gray.300"}
            margin="0 5px"
            cursor="pointer"
            onClick={() => setCurrentIndex(index)}
          />
        ))}
      </Flex>
      <Box
        as="button"
        position="absolute"
        left="10px"
        top="50%"
        transform="translateY(-50%)"
        onClick={prevSlide}
        bg="white"
        color="black"
        borderRadius="50%"
        p={2}
      >
        <ArrowBackIcon boxSize={6} />
      </Box>
      <Box
        as="button"
        position="absolute"
        right="10px"
        top="50%"
        transform="translateY(-50%)"
        onClick={nextSlide}
        bg="white"
        color="black"
        borderRadius="50%"
        p={2}
      >
        <ArrowForwardIcon boxSize={6} color={"black"}/>
      </Box>
    </Box>
  );
};

export default CarouselHero;