import React, { useEffect, useState } from "react";
import { Box, Heading, Text, Button, VStack, Flex } from "@chakra-ui/react";
import { ChevronLeftIcon } from "@chakra-ui/icons";
import { useNavigate } from "react-router-dom";

const BlackBoxBottom = ({ titulo1, info1, titulo2, info2 }) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <Box
      bg="#2E2E2E"
      color="white"
      p={4}
      borderRadius="24px"
      width={"100%"}
      style={{
        marginBottom: isMobile ? "2rem" : "0",
      }}
    >
      <Flex
        style={{
          flexDirection: isMobile ? "column" : "row",
        }}
        align="flex-start"
        spacing={2}
        padding="1rem"
        justifyContent={"space-around"}
      >
        <VStack alignItems={"flex-start"} >
          <Heading as="h1" fontSize="24px" fontWeight="bold" textAlign="left">
            {titulo1}
          </Heading>
          <Text fontSize="1rem" textAlign="left">
            {info1}
          </Text>
        </VStack>
        <VStack  alignItems={"flex-start"}>
          <Heading as="h1" fontSize="24px" fontWeight="bold" textAlign="left">
            {titulo2}
          </Heading>
          <Text fontSize="1rem" textAlign="left">
            {info2}
          </Text>
        </VStack>
      </Flex>
    </Box>
  );
};

export default BlackBoxBottom;
